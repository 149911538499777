import axios from 'axios';

const API = 'web-admin/arranques';

const ENDPOINTS = {

    getDetalleEmergente(idArranque){
        return axios.get(`${API}/${idArranque}/detalle`)
    },
    getEmergentes(){
        return axios.get(`${API}`)
    },
    guardarEmergente(payload){
        return axios.post(`${API}/guardar`, payload)
    },
    editarEmergente(payload){
        return axios.put(`${API}/editar-arranque`, payload)
    },
    editarEstadoEmergente(idEmergente, params){
        return axios.put(`${API}/${idEmergente}/edit-estado`, params)
    },
    eliminarEmergente(idEmergente){
        return axios.delete(`${API}/${idEmergente}/eliminar`)
    }

};

export default ENDPOINTS;