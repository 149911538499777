<template>
    <section class="mx-4 mt-4">
        <div class="row mx-0 pl-3 mt-3 mb-5">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'ventanas-emergentes' }">
                    Ventanas emergentes
                </el-breadcrumb-item>
                <el-breadcrumb-item>{{ $route.params.idEmergente ? 'Ventana' : 'Crear Ventana' }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="row mx-0">
            <div class="col-12">
                <div class="row">
                    <p class="col-auto f-20 f-500 mb-3">Ventana Emergente</p>
                    <div v-if="$route.params.idEmergente" class="col-7 ml-auto">
                        <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="border-0 shadow-sm">
                            <div slot="content" class="text-86">
                                <p class="cr-pointer" @click="eliminarEmergente">Eliminar</p>
                            </div>
                            <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-2">
                                <i class="icon-vertical-points" />
                            </div>
                        </el-tooltip>
                    </div>
                </div>
            </div>
            <div class="col-12" />
            <div class="col-6">
                <ValidationObserver ref="formPrincipal" class="w-100">
                    <div class="row mx-0 mb-3">
                        <div class="col-12 px-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required|max:60" name="Nombre">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Nombre
                                </p>
                                <el-input v-model="emergente.nombre" placeholder="Nombre ventana" class="w-100" size="small" />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3">
                        <div class="col-6 pl-0 pr-3 pl-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="fecha inicio">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Desde
                                </p>
                                <el-date-picker
                                v-model="emergente.inicio"
                                class="w-100"
                                type="date"
                                size="small"
                                format="dd/MM/yyyy"
                                value-format="dd-MM-yyyy"
                                range-separator="/"
                                placeholder="Seleccionar"
                                :picker-options="pickerOptionsDate"
                                />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-6 pl-0 pl-3 pr-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="hora inicio">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Hora
                                </p>
                                <!-- <el-date-picker v-model="emergente.horaInicio" type="datetime" placeholder="Seleccionar" class="w-100" size="small" /> -->
                                <el-time-select
                                v-model="emergente.horaInicio"
                                :options="pickerOptions"
                                placeholder="Seleccionar"
                                class="w-100" size="small" 
                                format="HH:mm"
                                value-format="HH:mm"
                                />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="row mx-0 mb-3">
                        <div class="col-6 pl-0 pr-3 pl-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="fecha fin">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Hasta
                                </p>
                                <el-date-picker
                                v-model="emergente.fin"
                                class="w-100"
                                type="date"
                                size="small"
                                format="dd/MM/yyyy"
                                value-format="dd-MM-yyyy"
                                range-separator="/"
                                placeholder="Seleccionar"
                                :picker-options="pickerOptionsDate"
                                />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                        <div class="col-6 pl-0 pl-3 pr-0">
                            <ValidationProvider v-slot="{errors}" tag="div" rules="required" name="hora fin">
                                <p class="f-12 text-whitesmoke pl-3">
                                    Hora
                                </p>
                                <!-- <el-date-picker v-model="emergente.horaFin" type="datetime" placeholder="Seleccionar" class="w-100" size="small" /> -->
                                <el-time-select
                                v-model="emergente.horaFin"
                                :options="pickerOptions"
                                placeholder="Seleccionar"
                                format="HH:mm"
                                value-format="HH:mm"
                                class="w-100" size="small" 
                                />
                                <p class="text-danger f-10">{{ errors[0] }}</p>
                            </ValidationProvider>
                        </div>
                    </div>
                </validationobserver>
                <div v-if="$route.params.idEmergente" class="d-middle">
                    <p class="f-15">Activar</p>
                    <el-switch v-model="emergente.estado" :active-value="1" :inactive-value="null" active-color="#00D9B5" inactive-color="#E6E6E6" class="mx-2" @change="editarEstadoEmergente" />
                </div>
            </div>
            <div class="col-auto ml-4">
                <p class="f-15 text-whitesmoke pl-3 mb-3">
                    Imagen
                </p>
                <div v-if="!$route.params.idEmergente" class="">
                    <el-upload
                    class="proyect-uploader"
                    action="#"
                    :auto-upload="false"
                    :on-change="saveImage"
                    :show-file-list="false"
                    >
                        <div class="box-ventana">
                            <img v-if="imageUrl" :src="imageUrl" class="vatar obj-cover" style="width: 200px; height: 200px;" />
                            <i v-else class="el-icon-plus avatar-uploader-icon" />
                        </div>
                    </el-upload>
                </div>
                <div v-else>
                    <div class="box-ventana">
                        <img v-if="imageUrl" :src="imageUrl" class="vatar obj-cover" style="width: 200px; height: 200px;" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-5 pl-4">
            <div class="col-auto px-0">
                <button v-if="!$route.params.idEmergente" type="button" class="btn-general btn btn-sm px-4" @click="guardarPantallaEmergente">
                    Guardar
                </button>
                <button v-else type="button" class="btn-general btn btn-sm px-4" @click="editarPantallaEmergente">
                    Guardar
                </button>
            </div>
        </div>
        <modal-eliminar ref="modalEliminarVentanaEmergente" titulo="Eliminar ventana emergente" mensaje="¿Desea eliminar la ventana emergente?" tamano="modal-md" class="f-15 text-center" @eliminar="eliminarPantallaEmergente" />
    </section>
</template>


<script>
import moment from 'moment'
import Emergente from '~/services/ventanas_emergentes'
export default{
    data(){
        return {
            pickerOptionsDate: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            nombre: '',
            imageUrl: '',
            file: {
                raw: null
            },
            pickerOptions: {
                // start: '08:30',
                step: '00:15',
                // end: '18:30'
            },
            emergente: {
                id: null,
                nombre: null,
                estado: null,
                imageUrl: null,
                inicio: null,
                horaInicio: null,
                fin: null,
                horaFin: null
            },
        }
    },
    created(){
        if(this.$route.params.idEmergente) this.getDetalleArranque()
    },
    methods: {
        guardar(){
            console.log("Guardando ventana")
        },
        eliminarEmergente(){
            this.$refs.modalEliminarVentanaEmergente.toggle()
        },
        async getDetalleArranque(){
            try {
                const { data } = await Emergente.getDetalleEmergente(this.$route.params.idEmergente)
                this.emergente= {
                    id: data.detalleEmergente.id,
                    nombre: data.detalleEmergente.nombre,
                    estado: data.detalleEmergente.estado,
                    inicio: moment(data.detalleEmergente.fecha_inicio).toDate(),
                    horaInicio: moment(data.detalleEmergente.fecha_inicio).format('HH:mm'),
                    fin: moment(data.detalleEmergente.fecha_fin).toDate('DD-MM-yyyy'),
                    horaFin: moment(data.detalleEmergente.fecha_fin).format('HH:mm')
                },
                this.imageUrl = data.detalleEmergente.imagen
                this.emergente.inicio = moment(this.emergente.inicio).format('DD-MM-yyyy')
                this.emergente.fin = moment(this.emergente.fin).format('DD-MM-yyyy')
            } catch (error){  
                return this.errorCatch(error)
            }
        },
        async guardarPantallaEmergente(){
            try {
                let validate = await this.$refs.formPrincipal.validate()

                if(!validate) return this.notificacion('','Por favor complete todos los campos')
                if(this.imageUrl == null || this.imageUrl == '') return this.notificacion('','El campo imagen es obligatorio')

                // const payload= {
                //     nombre: this.emergente.nombre,
                //     fecha_inicio: this.emergente.inicio + ' ' + this.emergente.horaInicio,
                //     fecha_fin: this.emergente.fin + ' ' + this.emergente.horaFin,
                //     imagen: this.file.raw,
                // }

                let formData = new FormData();
                formData.append('nombre', this.emergente.nombre);
                formData.append('fecha_inicio', this.emergente.inicio + ' ' + this.emergente.horaInicio);
                formData.append('fecha_fin', this.emergente.fin + ' ' + this.emergente.horaFin);
                formData.append('imagen', this.file.raw);
                    
                const { data } = await Emergente.guardarEmergente(formData)
                this.notificacion('Éxito', data.mensaje, 'success')

                if(!this.$route.params.idEmergente) this.$router.push({ name:'ventanas-emergentes' })
                else this.getDetalleArranque()

            } catch (error){
                return this.errorCatch(error)
            }
        },
        async editarPantallaEmergente(){
            try {

                let validate = await this.$refs.formPrincipal.validate()

                if(!validate) return this.notificacion('','Por favor complete todos los campos')
                
                const payload= {
                    id_arranque : this.$route.params.idEmergente,
                    nombre: this.emergente.nombre,
                    fecha_inicio: this.emergente.inicio + ' ' + this.emergente.horaInicio,
                    fecha_fin: this.emergente.fin + ' ' + this.emergente.horaFin,
                }
                const { data } = await Emergente.editarEmergente(payload)
                this.notificacion('Éxito', data.mensaje, 'success')
                this.getDetalleArranque()

            } catch (error){
                return this.errorCatch(error)
            }
        },
        async eliminarPantallaEmergente(){
            try {
                const { data } = await Emergente.eliminarEmergente(this.$route.params.idEmergente)
                this.notificacion('Éxito', data.mensaje, "success")
                this.$refs.modalEliminarVentanaEmergente.toggle()
                this.$router.push({ name:'ventanas-emergentes' })

            } catch (error){
                return this.errorCatch(error)
            }
        },
        async editarEstadoEmergente(){
            try {
                const params = {
                    estado: this.emergente.estado
                }
                const { data } = await Emergente.editarEstadoEmergente(this.$route.params.idEmergente, params)
                this.notificacion('Éxito', data.mensaje, 'success')
            } catch (error){
                return this.errorCatch(error)
            }
        },
        saveImage(file,fileList){
            const isJPG = file.raw.type === 'image/jpeg'
            const isPNG = file.raw.type === 'image/png'
            if(!isJPG && !isPNG){
                this.notificacion('Advertencia','Por favor seleccione solo imagenes','warning')
                return
            }

            this.file = file
            this.imageUrl = URL.createObjectURL(file.raw);

        },
    }
}


</script>
<style lang="scss" scoped>
.cropper-style{
	height:300px;
	width:300px;
	background-color:#f1f1f1;
	color: #868686;
	border-radius: 8px;
}
.box-ventana{
    width: 200px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #DFE4E8;
    border-radius: 4px;
}
</style>